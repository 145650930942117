export default {
  methods: {
    async updateItemsList() {
      this.close()
      await this.paginateTo()
      this.isDataSaved = false
      this.loading = false
    },
  },
}
